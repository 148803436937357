import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import {
  ContactForm,
  CopyWithFullHeightImage,
  CopyWithImages,
  Layout,
  SEO,
} from '../components';
import { CallToAction } from '../components/call-to-action';

function AboutPage({ data }) {
  return (
    <Layout
      heroImage={data.sanityAboutPage.heroImage.asset.fluid}
      heroAltText={data.sanityAboutPage.heroImage.altText}
    >
      <SEO title="Who Are Koalas In Care Inc?" />
      <WhoAreKoalasInCareInc
        data={data.sanityAboutPage.whoAreKoalasInCareInc}
      />
      <OurStory data={data.sanityAboutPage.ourStory} />
      <OurStoryContinued data={data.sanityAboutPage.ourStoryContinued} />
      <ContactForm />
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function WhoAreKoalasInCareInc({ data }) {
  return (
    <CopyWithFullHeightImage variant="WHITE" image={data.images[0]}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithFullHeightImage>
  );
}

WhoAreKoalasInCareInc.propTypes = {
  data: PropTypes.object.isRequired,
};

function OurStory({ data }) {
  return (
    <CopyWithImages variant="GREEN" images={data.images}>
      <SanityBlockContent
        blocks={data._rawText}
        className="prose prose-on-dark"
      />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithImages>
  );
}

OurStory.propTypes = {
  data: PropTypes.object.isRequired,
};

function OurStoryContinued({ data }) {
  return (
    <CopyWithImages reverse images={data.images}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithImages>
  );
}

OurStoryContinued.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  {
    sanityAboutPage {
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      ourStory {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      ourStoryContinued {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      whoAreKoalasInCareInc {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

export default AboutPage;
